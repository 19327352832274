.container-cards {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title {
  text-align: center;
  font-size: 3vw;
  font-weight: normal;
  color: rgb(199, 171, 43);
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 2vw;
}

.container-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 170%;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: flex-start;
    gap: 0vw;
  }

  .container-item {
    min-width: 10vw;
    min-height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
