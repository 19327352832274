.copy-number {
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  align-items: center;
  width: 100vw;
  justify-content: center;
}

.title {
  font-size: 2vw;
  color: rgb(199, 171, 43);
  margin: 0;
  margin-right: 1vw;
}

.number {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  border: none;
  border-bottom: 2px solid gray;
  padding: 0.5vw;
  background-color: transparent;
  text-align: center;
  width: 7vw;
  font-size: 1.5vw;
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 4.5vw;
  }

  .number {
    width: 12vw;
    font-size: 5vw;
  }
}
