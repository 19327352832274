body {
  margin: 0;
  font-family: Oswald;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container{
  display: flex
  ;
      flex-direction: column;
      justify-content: center;
}

@font-face {
  font-family: Oswald;
  src: url(./assets/fonts/Oswald.ttf);
}
