/* Input.module.css */
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(199, 171, 43);
    border-radius: 10px;
    padding: 20px;
    background-color: white;
    outline: none;
    cursor: pointer;
    transition: border 0.3s, background-color 0.3s;
    margin-left: 20%;
    margin-right: 20%;
}

.text {
    font-size: 155%;
    color: rgb(199, 171, 43);
    text-align: center;
}
  
  
.pdfPage {
    display: flex;
  justify-content: center;
  align-items: center;
    margin: 0 auto; 
    border: 2px solid rgb(199, 171, 43);
    border-radius: 10px;
    padding: 10px;
  }

.pages-container {
    display: flex;
    justify-content: space-between
}

.select-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.pagesText {
    text-align: center;
}

.buttonsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonsContainer button {
    cursor: pointer;
    border: none;
    background-color: rgb(199, 171, 43);
    font-size: 30px;
    color: white;
    border: 2px solid white;
}

.buttonsContainer button:disabled {
    background-color: gray;
    color: white;
  }

  
.numberInput {
    display: flex;
    width: 20%;
    margin-left: 5%;
}

@media screen and (max-width: 500px) {

.text {
    font-size: 105%;
}

.pages-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.select-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.pagesText {
    text-align: center;
    font-size: 80%;
}

.buttonsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonsContainer button {
    cursor: pointer;
    border: none;
    background-color: rgb(199, 171, 43);
    font-size: 30px;
    color: white;
    border: 2px solid white;
}

.buttonsContainer button:disabled {
    background-color: gray;
    color: white;
  }

  
.numberInput {
    display: flex;
    width: 20%;
    margin-left: 5%;
}
}