.footer {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 3px solid rgb(199, 171, 43);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  box-shadow: -5px -5px 10px rgba(0, 0, 0, 0.4);
}

.title-Footer {
  flex-direction: row;
}

.title-Footer h2 {
  font-size: 2vw;
  font-weight: normal;
}

.container-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  width: auto;
  margin: 0vw;
  margin-bottom: 1vw;
}

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vw;
}

.footer-logo {
  width: 2vw;
  padding: 0.2vw;
}

.footer-text {
  font-size: 1.3vw;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

@media screen and (max-width: 500px) {
  .title-Footer h2 {
    font-size: 4vw;
  }

  .container-info {
    flex-direction: column;
  }

  .footer-logo {
    width: 5vw;
  }

  .footer-text {
    margin: 1.5vw;
    font-size: 3vw;
  }
}
