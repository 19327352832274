.header {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid rgb(199, 171, 43);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  z-index: 1;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
}

.Logo {
  max-width: 200px;
  width: 100%;
  margin: 7%;
  margin-left: 0%;
}
