.stuck {
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 3vw;
  pointer-events: none;
}

.stuck .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid rgb(199, 171, 43);
  border-radius: 10px;
  background-color: white;
  justify-content: center;
  width: 16.3vw;
  margin-bottom: 2vh;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
}

.stuck .container header {
  display: none;
}

.stuck .text-container p {
  display: none;
}

.stuck .order-container {
  display: flex;
  text-align: center;
  flex-direction: column;
  margin-right: 0;
}

.stuck .inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.stuck .button-container {
  display: none;
}

.unstuck {
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 3vh;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid rgb(199, 171, 43);
  border-radius: 10px;
  padding: 0.5vw;
  background-color: white;
  justify-content: center;
  width: 40vw;
  margin-bottom: 2vh;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
  transition: width 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.container header {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  width: -webkit-fill-available;
}

.container img {
  width: 1.3vw;
}

.inner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.3vw;
}

.text {
  font-size: 2.3vw;
  color: rgb(199, 171, 43);
  margin: 0;
}

.total-order {
  font-size: 2.3vw;
  margin: 0;
  margin-left: 0.5vw;
}

.order-container {
  display: flex;
  flex-direction: row;
  margin-right: 2.5vw;
}

.order-container p {
  white-space: nowrap;
}

.text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-container p {
  text-align: center;
  width: 30vw;
  margin: 0;
  margin-bottom: 2vh;
  font-size: 2vh;
}

.button-container button {
  border: none;
  background-color: transparent;
  font-size: 2vw;
  color: rgb(199, 171, 43);
  font-weight: bold;
  cursor: pointer;
}

.button-container button:disabled {
  color: rgb(203, 203, 203);
  cursor: not-allowed;
}

@media screen and (max-width: 500px) {
  .stuck {
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    pointer-events: all;
    margin-right: 0;
  }

  .stuck .container header {
    display: flex;
  }

  .stuck .text-container p {
    display: flex;
  }

  .stuck .order-container {
    flex-direction: row;
  }

  .stuck .inner-container {
    flex-direction: row;
  }

  .stuck .button-container {
    display: flex;
  }

  .container {
    width: 90vw;
    height: auto;
    margin-left: 0;
    margin-bottom: 2vh;
  }

  .stuck .container {
    margin-bottom: 2vh;
    transition: none;
    margin-left: 0;
  }

  .stuck .container {
    width: 90vw;
    height: auto;
    margin-bottom: 2vh;
    align-items: center;
    margin-bottom: 2vh;
  }

  .container img {
    width: 4vw;
  }

  .text {
    font-size: 5vw;
  }

  .total-order {
    font-size: 5vw;
  }

  .text-container p {
    width: auto;
  }

  .button-container button {
    font-size: 5vw;
  }

  .inner-container {
    margin-bottom: 2vh;
  }
  .stuck .inner-container {
    margin-bottom: 2vh;
  }

  .stuck .inner-container {
    margin-bottom: 2vh;
  }

  .unstuck {
    margin-right: 0;
  }

  .stuck .order-container {
    margin-right: 2.5vw;
  }
}
