.backdrop{
    position: fixed;
    top:0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.75);
    z-index: 998;
}

.container {
    position: fixed;
    top: 30vh;
    left: 29vw;
    z-index: 999;
    border: 5px solid rgb(199, 171, 43);
    border-radius: 15px;
    background-color: white;
    height: auto;
    overflow: hidden;
    width: 40vw;
}

.container header {
    background-color: white;
    padding: 0.6vw;
}

.container header h1{
    margin: 0;
    font-size: 2.3vw;
    text-align: center;
    color: rgb(199, 171, 43);
    font-weight: normal;
    margin-top: 4vh;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content p {
    margin-top: 3vh;
    text-align: center;
    font-size: 1.4vw;
    margin-bottom: 4vh;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
}

.container footer {
    display: flex;
    justify-content: flex-end;
}

.container button {
    border: none;
    background-color: rgb(199, 171, 43);
    color: white;
    border-radius: 7px;
    font-size: 1.5vw;
    font-weight: bold;
    margin-right: 5vw;
    padding: 0.3vw;
    cursor: pointer;
    margin-bottom: 2vh;
}

.spinner-container {
    display: flex;
    justify-content: center;
    margin-top: 3vh;
    flex-direction: column;
    align-items: center;
}

.spinner-container p {
    margin: 0;
    margin-bottom: 2vh;
    font-size: 1.5vw;
}

.spinner {
    animation: spin 2s linear infinite;
    font-size: 48px;
    color: rgb(199, 171, 43);
    margin-bottom: 2vh;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media screen and (max-width: 500px) {

    .container {
        left: 12vw;
        height: auto;
        width: 75vw;
        border: 3px solid rgb(199, 171, 43);
    }
    
    .container header h1{
        font-size: 8vw;
        margin-top: 1vh;
    }
    
    .content p {
        margin-top: 2vh;
        font-size: 4vw;
        margin-bottom: 2vh;
    }
    
    .container button {
        font-size: 95%;
        padding: 0.8vw;
        margin-bottom: 2vh;
    }
    
    .spinner-container {
        display: flex;
        justify-content: center;
        margin-top: 3vh;
        flex-direction: column;
        align-items: center;
    }
    
    .spinner-container p {
        margin: 0;
        margin-bottom: 2vh;
        font-size: 80%;
        margin-left: 2vw;
        margin-right: 2vw;
    }
    
  }