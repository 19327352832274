.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 526px;
}

.spinner {
  animation: spin 2s linear infinite;
  font-size: 48px;
  color: rgb(199, 171, 43);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 500px) {
  .container {
    width: 50px;
    height: 210px;
  }
}
