.form {
  display: flex;
  justify-content: center;
  width: 100%;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
}

.credentials {
  display: flex;
  flex-direction: row;
  width: 80vw;
  justify-content: center;
}

.credential {
  padding: 1vh 0 1vh 0;
  display: flex;
  flex-direction: row;
}

.voice {
  font-size: 2vw;
  color: rgb(199, 171, 43);
  margin-right: 1vw;
}


.credential input {
  border: none;
  background-color: transparent;
  border-bottom: 0.5px solid gray;
  font-size: 1.6vw;
  width: 20vw;
  margin-right: 2vw;
}

.credential input:focus{
  outline: none;
  border-bottom: 2px solid rgb(199, 171, 43);
}

.credential.invalid input {
  border-bottom: 2px solid rgb(199, 43, 43);
  background: rgb(255, 231, 231);
}

@media screen and (max-width: 500px) {

  .credentials {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .credential {
    padding: 0.6vh;
  }
  
  .voice {
    font-size: 2.4vh;
    color: rgb(199, 171, 43);
    margin-right: 1vw;
  }

  .credential input {
    font-size: 4vw;
    width: 48vw;
  }
  
}