.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.title {
  color: rgb(199, 171, 43);
  font-weight: normal;
  font-size: 5vw;
  display: flex;
  justify-content: center;
  margin-top: 12vh;
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80vw;
  font-size: 1.8vw;
  font-weight: 500;
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 6vw;
    margin-top: 13vh;
  }

  .text {
    font-size: 4vw;
  }
}

@media screen and (max-width: 900px) and (min-width: 500px) {
  .title {
    margin-top: 28vh;
  }
}
