.container-line {
  display: flex;
  justify-content: center;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.line-delimiter {
  border: 1px solid rgb(199, 171, 43);
  width: 80vw;
  border-radius: 50px;
}
