.page-range {
  display: flex;
  justify-content: center;
}

.title {
  font-size: 2vw;
  color: rgb(199, 171, 43);
  margin: 0;
  margin-right: 1vw;
}

.options {
  display: flex;
  justify-content: flex-start;
  margin: 0;
}

.container-text {
  display: flex;
  align-items: center;
  margin: 0.5vh;
  cursor: pointer;
}

.text {
  font-size: 2vw;
  margin: 0;
}

.text-page {
  font-size: 1.7vw;
  margin: 0%;
  text-align: center;
  margin-left: 2vw;
}

.inputRowDivS {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.inputRowDivS input {
  margin-right: 2vw;
  width: 5vw;
}

.inputRowDivS.invalid input {
  border-bottom: 2px solid rgb(199, 43, 43);
  background: rgb(255, 231, 231);
}

.invalid-input {
  position: absolute;
  color: red;
  left: 63vw;
}

.number {
  width: 5vw;
  border: transparent;
  border-bottom: 1px solid gray;
  background-color: transparent;
  margin-left: 0.7vw;
  font-size: 1.7vw;
  text-align: center;
}

.options input[type="radio"] {
  display: none;
}

.checkmark {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid rgb(199, 171, 43);
  border-radius: 50%;
  margin-right: 8px;
}

.options input[type="radio"]:checked + label .checkmark {
  background-color: rgb(199, 171, 43);
  border-color: rgb(199, 171, 43);
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 4.5vw;
  }

  .text {
    font-size: 4vw;
  }

  .text-page {
    font-size: 3.8vw;
  }
  .inputRowDivS {
    width: 20vw;
  }

  .inputRowDivS input {
    margin-right: 2vw;
    width: 12vw;
  }
  

  .number {
    font-size: 5vw;
    margin: 0;
  }

  .invalid-input {
    left: 70vw;
  }
}
